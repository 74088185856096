import { IonContent, IonPage, IonAlert, IonFab, IonFabButton,  IonToast, IonIcon, IonLabel, IonButtons, IonInput, IonButton, IonModal, IonHeader, IonToolbar, IonTitle, IonCardTitle, IonCard, IonCardHeader,IonCardSubtitle, IonProgressBar, IonReorderGroup, IonItem, IonReorder, IonSpinner } from '@ionic/react';
import { ItemReorderEventDetail, OverlayEventDetail } from '@ionic/core/components';
import { addOutline, musicalNote, pencil, trash, trashOutline } from 'ionicons/icons';
import React, { useEffect, useState, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import './SongDetail.css';
import API from '../api';
import { format, parseISO } from 'date-fns';
import { useLocalStorage } from 'usehooks-ts';
import { useUploadForm } from '../components/hook';

interface SongDetailProps extends RouteComponentProps {
    categories?: any;
    songs?: any;
    playlists?: any;
    recordings?: any;
}

interface RecordingData {
    name: string;
    recordingMedia: File | null;
    song_ID?: number;
}

const SongDetail: React.FC<SongDetailProps> = ({match, history, categories, songs, playlists, recordings}) => {
    const [song, setSong] = useState<any>(null);
    const [showAlertDeleteSong, setShowAlertDeleteSong] = useState(false);
    const [showAlertDeleteRecording, setShowAlertDeleteRecording] = useState(false);
    const [selectedRecording, setSelectedRecording] : any = useState(null);
    const [toastRecordingMessage, setToastRecordingMessage] = useState<string>("");
    const [chordView, setChordView] = useState('block');
    const [uploading, setUploading] = useState(false);
    const modal = useRef<HTMLIonModalElement>(null);
    const modalRecording = useRef<HTMLIonModalElement>(null);
    const [recordingName, setRecordingName] = useState<string>("");
    const [recordingFile, setRecordingFile] = useState<any>(null);
    const [showToastRecording, setShowToastRecording] = useState(false);
    const [showToastPlaylist, setShowToastPlaylist] = useState(false);
    const [loggedIn] = useLocalStorage('loggedIn', false);
    const [focusMode, setFocusMode] = useLocalStorage('focusMode', false);
    const [formValues, setFormValues] = useState<RecordingData>({name: "", recordingMedia: null});

    const { isSuccess, uploadForm, progress } = useUploadForm(process.env.REACT_APP_SONGBOOK_API + "/add/recording");

    const maxLineLength = 36;
    const timerRef : any = useRef();
    const isLongPress : any = useRef();
    const startTouchPos = useRef({ x: 0, y: 0 });
    const isScrolling = useRef(false);

    const mode = (arr: any) => {
        return arr.sort((a: any,b: any) =>
              arr.filter((v: any) => v===a).length
            - arr.filter((v: any) => v===b).length
        ).pop();
    }

    const confirmDelete = (e: React.MouseEvent<HTMLIonIconElement, MouseEvent>, r: {id: number, name: string, pathtofile: string, song_id: number, user_id: number}) => {
        e.stopPropagation();
        setSelectedRecording(r);
        setShowAlertDeleteRecording(true);
    };

    useEffect(() => {
        if (isSuccess) {
            setToastRecordingMessage("Nahrávka bola úspešne pridaná k pesničke");
            setShowToastRecording(true);
            setTimeout(()=>{
                window.location.reload();
            }, 1000)
        }
    }, [isSuccess]);

    useEffect(() => {
        let params : any = match.params;
        if (params.id && song === null && songs && songs?.length !== 0) {
            let newSong = songs.find((s: { id: number; }) => s.id === Number(params.id));
            let newTruncatedContent : any[] = [];
            if (newSong) {
                let finalIndent : number = 0;
                let indents : number[] = [];
                let indentStr : string = ``;
                for (let line of newSong.content) {
                    let indent = line.text.search(/\S/);
                    if (indent >= 0) indents.push(indent);
                }
                finalIndent = mode(indents);
                indentStr = new Array(finalIndent).fill(' ').join('');

                for (let line of newSong.content) {
                    if (line.text.length > maxLineLength) {
                        const splitPoint = line.text.substring(0, maxLineLength).lastIndexOf(" ");
                        const splitPointEnd = line.text.substring(splitPoint).search(/\S/);
                        const firstHalfText = line.text.substring(0, splitPoint);
                        const secondHalfText = line.text.substring(splitPoint + splitPointEnd);
                        let firstHalfChords: { chord: string, pos: number }[] = [];
                        let secondHalfChords: { chord: string, pos: number }[] = [];
                        if (line.chords.length) {
                            for (let chord of line.chords) {
                                if (chord.pos < splitPoint) firstHalfChords.push(chord);
                                else {
                                    let newChord = {chord: chord.chord, pos: Math.max(chord.pos - splitPoint - splitPointEnd + indentStr.length, 0)};
                                    secondHalfChords.push(newChord);
                                }
                            }
                        }
                        newTruncatedContent.push({text: firstHalfText, chords: firstHalfChords});
                        newTruncatedContent.push({text: indentStr + secondHalfText, chords: secondHalfChords});
                    } else {
                        newTruncatedContent.push(line);
                    }
                }
                newSong.content = newTruncatedContent;
                console.log(newSong);
                setSong(newSong);
                document.title = newSong.name;
            }
     
            
        }
    }, [song, match, songs]);

    const displayChords = (chords: { chord: string, pos: number }[]) : string => {
        if (chords.length === 0) return ` `;
        let chordStr : string = ``;
        for (let chord of chords) {
            let numSpaces = Math.max(chord.pos - chordStr.length, 0);
            chordStr += new Array(numSpaces).fill(' ').join('');
            chordStr += chord.chord;
        }
        return chordStr;
    }

    const getCategoryColor = (categoryId: any) => {
        return categories && categories.find((c: { id: any; }) => c.id === categoryId).color;
    }

    const addSongToPlaylist = (playlist: { id: number; name: string; date: string; song_ids: Array<any>; user_id: number}) => {
        let song_ids = playlist.song_ids;
        if (song_ids.indexOf(song.id) === -1) {
            song_ids.push(song.id);

            API.post("/update/playlist", {
                id: playlist.id,
                name: playlist.name,
                date: playlist.date,
                user_id: 1, 
                songs_id: song_ids
              })
            .then((res: any) => {
               if (res.status === 200) history.push(`/playlist/${playlist.id}`);
            })
            .catch((error: any)=>{
              
            })
            modal.current?.dismiss('confirm');
            setShowToastPlaylist(true);
        } else {
            console.log("Song is already in playlist");
        }        
    }

    const handleRecordingNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecordingName(event.target.value);
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          name: event.target.value,
        }));
    };

    const handleRecordingFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.files && event.target.files[0]) {
            const maxAllowedSize = 4.5 * 1024 * 1024;
            if (event.target.files[0].size > maxAllowedSize) {
                // Here you can ask your users to load correct file
                event.target.value = '';
                setToastRecordingMessage("Vybraný súbor je príliš veľký. Maximálna veľkosť je 4.5MB");
                setShowToastRecording(true);
                return;
            }
        }


        setRecordingFile(event.target.files ? event.target.files[0] : null);
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            recordingMedia: event.target.files ? event.target.files[0] : null,
        }));
    }

    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'confirm') {
            return;
        }
    }

    const confirmRecording = async () => {
        if (recordingFile && recordingName) {
            setUploading(true);
            setRecordingFile(null);
            setRecordingName("");
            const formData = new FormData();
            formData.append("name", formValues.name);
            formData.append("song_id", song.id);
            formData.append("user_id", "1");
            formData.append("recording_order", String(getCurrentRecordingOrder()));
            formValues.recordingMedia && formData.append("file", formValues.recordingMedia);            
            await uploadForm(formData);
            modalRecording.current?.dismiss('confirm');
            return setUploading(false);
        } else {
            setToastRecordingMessage("Zadajte názov nahrávky a vyberte súbor");
            setShowToastRecording(true);
        }
    }

    const deleteRecording = () => {
        API.post("/delete/recording", selectedRecording)
        .then((res: any) => {
            if (res.status === 200) {
                setToastRecordingMessage("Nahrávka bola vymazaná");
                setShowToastRecording(true);
                setTimeout(()=>{
                    window.location.reload();
                }, 1000)
            }
        })
        .catch((error: any)=>{
          
        })
    }

    const deleteSong = (s: {id: number, name: string, category_id: number, content: Array<any>}) => {
        API.post("/delete/song", s)
        .then((res: any) => {
            if (res.status === 200) {
                history.push('/songs');
            }
        })
        .catch((error: any)=>{
          
        })
    }

    function onWillDismissRecording(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'confirm') {
            return;
        }
    }

    
    function handleOnClick() {
        if (isLongPress.current || isScrolling.current) return;
        // Your focusMode logic here
    }
    
    function handleOnMouseDown(e: any) {
        startTouchPos.current = { x: e.clientX, y: e.clientY };
        isScrolling.current = false;
        startPressTimer();
    }
    
    function handleOnTouchStart(e: any) {
        startTouchPos.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        isScrolling.current = false;
        startPressTimer();
    }
    
    function handleOnMouseUp(e: any) {
        clearTimeout(timerRef.current);
        isScrolling.current = false;
    }
    
    function handleOnTouchEnd(e: any) {
        clearTimeout(timerRef.current);
        isScrolling.current = false;
    }
    
    function handleOnMouseMove(e: any) {
        if (Math.abs(e.clientX - startTouchPos.current.x) > 10 || Math.abs(e.clientY - startTouchPos.current.y) > 10) {
            isScrolling.current = true; // Movement detected, consider this a scroll or swipe
        }
    }
    
    function handleOnTouchMove(e: any) {
        if (Math.abs(e.touches[0].clientX - startTouchPos.current.x) > 10 || Math.abs(e.touches[0].clientY - startTouchPos.current.y) > 10) {
            isScrolling.current = true; // Movement detected, consider this a scroll or swipe
        }
    }
    
    function startPressTimer() {
        isLongPress.current = false;
        timerRef.current = setTimeout(() => {
            if (!isScrolling.current) {
                isLongPress.current = true;
                setFocusMode((prevValue) => !prevValue);
            }
        }, 1000);
    }

    const getAudioType = (filename: string) : string => {
        const fileExt = filename.split('.').pop();
        if (fileExt === 'mp3') return `audio/mpeg`;
        else if (fileExt === 'm4a') return `audio/x-m4a`;
        else return ``;
    }

    const getCurrentRecordingOrder = () : number => {
        const relatedRecordings = recordings.filter((r: { song_id: any }) => r.song_id === song?.id);
        const maxRecordingOrder = relatedRecordings.length > 0 
            ? Math.max(...relatedRecordings.map((r: { recording_order: number; }) => r.recording_order))
            : 0;
        console.log(maxRecordingOrder + 1);
        return maxRecordingOrder + 1;
    }

    const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
        const fromIndex = event.detail.from;
        const toIndex = event.detail.to;

        // Get only recordings related to the current song and sort by `recording_order`
        const relatedRecordings = recordings
            .filter((r: { song_id: any }) => r.song_id === song?.id)
            .sort((a: any, b: any) => a.recording_order - b.recording_order);

        // Adjust recording_order based on drag direction
        if (fromIndex < toIndex) {
            // Dragging down: increment recording_order for items between fromIndex and toIndex
            relatedRecordings[fromIndex].recording_order = toIndex + 1;
            for (let i = fromIndex + 1; i <= toIndex; i++) {
                relatedRecordings[i].recording_order -= 1;
            }
        } else if (fromIndex > toIndex) {
            // Dragging up: decrement recording_order for items between toIndex and fromIndex
            relatedRecordings[fromIndex].recording_order = toIndex + 1;
            for (let i = toIndex; i < fromIndex; i++) {
                relatedRecordings[i].recording_order += 1;
            }
        }
        updateRecordings(relatedRecordings);

        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        event.detail.complete();
    }

    const updateRecordings = (relatedRecordings: any) => {
        Promise.all(
            relatedRecordings.map((recording: any) =>
                API.post("/update/recording", recording)
                    .then((res) => {
                        if (res.status === 200) {
                            console.log(`Recording ${recording.id} updated successfully.`);
                        }
                    })
                    .catch((error) => {
                        console.error(`Error updating recording ${recording.id}:`, error);
                    })
            )
        ).then(() => {
            console.log("All recordings updated successfully.");
            setToastRecordingMessage("Nahrávky boli preusporiadané");
            setShowToastRecording(true);
        }).catch((error) => {
            console.error("Error in updating recordings:", error);
        });
    };

    return (
        <IonPage>
            <IonContent fullscreen
                onClick={handleOnClick}
                onMouseDown={handleOnMouseDown}
                onTouchStart={handleOnTouchStart}
                onMouseUp={handleOnMouseUp}
                onTouchEnd={handleOnTouchEnd}
                onMouseMove={handleOnMouseMove}
                onTouchMove={handleOnTouchMove}>
                {!song && <div className="spinner-wrapper"><IonSpinner name="dots"></IonSpinner></div>}
                <div className="xc" style={{borderLeft: song && `5px solid ${getCategoryColor(song.category_id)}`}}>
                    <div className="sb-song">
                        {song?.content.map((line: { chords: { chord: string, pos: number }[]; text: string }, i: React.Key) => {
                            return (
                                <div key={i} className="line">
                                    {line.chords.length !== 0 && <div>{` `}</div>}
                                    {(line.chords.length !== 0 || line.text.length === 0) && <div className="chords" style={{display: chordView}}>{displayChords(line.chords)}</div>}
                                    <div className="text">{line.text || ` `}</div>
                                </div>
                            );
                        })}
                    </div>
                    <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
                        {song && recordings && recordings.length !== 0 && recordings.filter((r: { song_id: any; }) => r.song_id === song?.id).sort((a: any, b: any) => a.recording_order - b.recording_order).map((r: any) => {
                            return (
                                <IonItem class="recording" key={r.id}>
                                    <IonLabel>
                                        <div className="recording-cont">
                                            <span>{r.name}</span>
                                            <span>
                                                <audio controls>
                                                    <source src={r.pathtofile} type={getAudioType(r.pathtofile)}></source>
                                                </audio>
                                            </span>
                                        </div>
                                    </IonLabel>                                    
                                    {loggedIn && <IonIcon icon={trashOutline} style={{cursor: "pointer", fontSize: "20px", height: "54px", verticalAlign: "top", marginLeft: "10px"}}
                                        onClick={(e) => {confirmDelete(e, r)}}></IonIcon>}
                                    
                                    {loggedIn && recordings.filter((r: { song_id: any; }) => r.song_id === song?.id).length > 1 && <IonReorder slot="start"></IonReorder>}
                                </IonItem>
                                
                            );
                        })}
                    </IonReorderGroup>
                    {song && <IonButton style={{margin: `20px`}} id="open-modal-recording">Pridať nahrávku</IonButton>}
                    <IonToast isOpen={showToastRecording} onDidDismiss={() => setShowToastRecording(false)} message={toastRecordingMessage} duration={1500} />
                    {song && <IonModal ref={modalRecording} trigger="open-modal-recording" onWillDismiss={(ev) => onWillDismissRecording(ev)}>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="start">
                                    <IonButton onClick={() => modalRecording.current?.dismiss()}>Zrušiť</IonButton>
                                </IonButtons>
                                <IonTitle>Pridať nahrávku</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton strong={true} onClick={() => confirmRecording()}>Hotovo</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            <IonLabel position="floating">Názov nahrávky</IonLabel>
                            <IonInput value={recordingName} placeholder="Názov, napríklad hlas 1" onIonChange={(event: any) => {handleRecordingNameChange(event)}}></IonInput>
                            <div><label>Vybrať súbor:</label></div>
                            <input type="file" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRecordingFileChange(event)} accept=".m4a,.mp3" />
                            {uploading && <IonProgressBar value={progress}></IonProgressBar>}
                        </IonContent>
                    </IonModal>}
                </div>
                
                <IonModal ref={modal} trigger="open-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Pridať do playlistu</IonTitle>                            
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                    {playlists && playlists.map((playlist: { id: number; name: string; date: string; song_ids: Array<any>; user_id: number}, i: React.Key | null | undefined) => {
                        return (
                            <IonCard key={i} button={true} onClick={() => {addSongToPlaylist(playlist);}}>
                            <IonCardHeader>
                                <IonCardTitle>{playlist.name}</IonCardTitle>
                                <IonCardSubtitle>{format(parseISO(playlist.date), 'd. M. yyyy - HH:mm')}</IonCardSubtitle>
                            </IonCardHeader>
                            </IonCard>
                        );
                    })}
                    <IonCard button={true} href={`/playlistadd?songid=${song?.id}`}>
                        <IonCardHeader>
                            <IonCardTitle>Nový playlist</IonCardTitle>
                        </IonCardHeader>
                        </IonCard>
                    </IonContent>
                </IonModal>
                <IonToast isOpen={showToastPlaylist} onDidDismiss={() => setShowToastPlaylist(false)} message="Pesnička bola pridaná do playlistu" duration={1500} />
                {!focusMode && song && <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton onClick={() => {chordView === 'block' ? setChordView('none') : setChordView('block')}}>
                        <IonIcon icon={musicalNote} />
                    </IonFabButton>
                </IonFab>}
                {!focusMode && loggedIn && song && <IonFab slot="fixed" vertical="bottom" horizontal="start">
                    <IonFabButton color="danger" onClick={() => {setShowAlertDeleteSong(true);}}>
                        <IonIcon icon={trash} />
                    </IonFabButton>
                </IonFab>}
                {!focusMode && loggedIn && song && <IonFab slot="fixed" vertical="top" horizontal="start">
                    <IonFabButton color="warning" href={`/songadd?usid=${song.id}&usname=${song.name}&uscategoryid=${song.category_id}`}>
                        <IonIcon icon={pencil} />
                    </IonFabButton>
                </IonFab>}
                {!focusMode && loggedIn && song && <IonFab slot="fixed" vertical="top" horizontal="end">
                    <IonFabButton id="open-modal" color="success">
                        <IonIcon icon={addOutline} />
                    </IonFabButton>
                </IonFab>}
                <IonAlert
                    isOpen={showAlertDeleteSong}
                    onDidDismiss={() => setShowAlertDeleteSong(false)}
                    header="Potvrdiť vymazanie"
                    message={`Naozaj chcete vymazať pieseň: ${song?.name}`}
                    buttons={[{
                        text: 'Zrušiť',
                        role: 'cancel',
                        handler: () => {
                            setShowAlertDeleteSong(false);
                        },
                      },
                      {
                        text: 'OK',
                        role: 'confirm',
                        handler: () => {
                            deleteSong(song);
                        },
                      },]}
                />
                <IonAlert
                    isOpen={showAlertDeleteRecording}
                    onDidDismiss={() => setShowAlertDeleteRecording(false)}
                    header="Potvrdiť vymazanie"
                    message={`Naozaj chcete vymazať nahrávku: ${selectedRecording?.name}`}
                    buttons={[{
                        text: 'Zrušiť',
                        role: 'cancel',
                        handler: () => {
                            setShowAlertDeleteRecording(false);
                        },
                      },
                      {
                        text: 'OK',
                        role: 'confirm',
                        handler: () => {
                            deleteRecording();
                        },
                      },]}
                />
            </IonContent>
        </IonPage>
    );
};

export default withRouter(SongDetail);
