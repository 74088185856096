import { IonCardSubtitle, IonLoading, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCardTitle, IonCard, IonCardHeader, IonFab, IonIcon, IonFabButton, IonAlert } from '@ionic/react';
import './Playlists.css';
import API from '../api';
import { add, trashOutline } from 'ionicons/icons';
import { RouteComponentProps, withRouter } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { DateTime } from 'luxon';

interface PlaylistsProps extends RouteComponentProps {
  playlists?: any;
}

const Playlists: React.FC<PlaylistsProps> = ({history, location, playlists}) => {
  const [loggedIn] = useLocalStorage('loggedIn', false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] : any = useState(null);


  useEffect(() => {
    if(location.search) {
        const search = location.search;
        const params = new URLSearchParams(search);
        const refresh : Boolean | null = Boolean(params.get('refresh'));
        if (refresh) {
          setTimeout(() => {
            history.push({
              pathname: '/playlists',
              search: ''
            });
            window.location.reload();
          }, 1000);
        }
        
    }
  }, [location, history]);

  const confirmDelete = (e: React.MouseEvent<HTMLIonIconElement, MouseEvent>, p: {id: number, name: string, date: string, user_id: number, songs_id: Array<any>}) => {
    e.stopPropagation();
    setSelectedPlaylist(p);
    setShowAlert(true);
  };

  const deletePlaylist = () => {
    API.post("/delete/playlist", selectedPlaylist)
    .then((res: any) => {
        if (res.status === 200) {
          window.location.reload();
        }
    })
    .catch((error: any)=>{
      
    })
  }

  const showPlaylist = (id: number) => {
    history.push(`/playlist/${id}`);
  }

  const isToday = (date: string): boolean => {
    const givenDate = DateTime.fromISO(date);
    const today = DateTime.now();
    return givenDate.hasSame(today, 'day');
  };

  const dateInThePast = (date: string): boolean => {
    const givenDate = DateTime.fromISO(date).startOf('day');
    const today = DateTime.now().startOf('day');
    return givenDate < today;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Playlisty</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={!playlists}
          message={'Chvíľku strpenia...'}
        />

        {playlists && playlists.sort((a: { date: string | number | Date; }, b: { date: string | number | Date; }) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((playlist: { id: number; name: string; date: string; user_id: number; songs_id: any[]; }, i: React.Key | null | undefined) => {
              return (
                <IonCard key={i} button={true} onClick={() => {showPlaylist(playlist.id)}} className={`${dateInThePast(playlist.date) ? 'past' : ''} ${isToday(playlist.date) ? 'today' : ''}`}>
                  <IonCardHeader>
                    <IonCardTitle>{playlist.name}</IonCardTitle>
                    <IonCardSubtitle>{DateTime.fromISO(playlist.date, {zone: 'utc'}).toFormat('d. M. yyyy - HH:mm')}</IonCardSubtitle>
                    <IonIcon icon={trashOutline} style={{position: "absolute", cursor: "pointer", fontSize: "25px", top: "25px", right: "15px"}}
                                    onClick={(e) => {confirmDelete(e, playlist)}}></IonIcon>
                  </IonCardHeader>
                </IonCard>
              );
        })}
        {loggedIn && <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton href="/playlistadd">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>}
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header="Potvrdiť vymazanie"
          message={`Naozaj chcete vymazať playlist: ${selectedPlaylist?.name}`}
          buttons={[{
              text: 'Zrušiť',
              role: 'cancel',
              handler: () => {
                  setShowAlert(false);
              },
          },
          {
              text: 'OK',
              role: 'confirm',
              handler: () => {
                deletePlaylist();
              },
          },]}
      />
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Playlists);